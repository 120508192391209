import { useContext, useMemo } from 'react';
import { CaseDashboardContext } from '@pages/CaseDashboard/CaseDashboardContext';
import { getTodayDate } from '@utils/dates';
import { useUserOrgCase } from '@context/UserOrgCaseContext';

interface UseFiltersInitialDates {
  defaultStartDate: string;
  defaultEndDate: string;
  startDate: string;
  endDate: string;
}

export const useFiltersInitialDates = (): UseFiltersInitialDates => {
  const { filters } = useContext(CaseDashboardContext);

  const { selectedCase } = useUserOrgCase();

  const today = useMemo(() => getTodayDate(), []);

  const submissionEndDate = useMemo(
    () =>
      selectedCase?.claimSubmissionEndDate > today
        ? today
        : selectedCase?.claimSubmissionEndDate,
    [selectedCase?.claimSubmissionEndDate, today]
  );

  const startDate = useMemo(
    () => filters?.startDate || selectedCase?.claimSubmissionStartDate,
    [selectedCase?.claimSubmissionStartDate, filters?.startDate]
  );

  const endDate = useMemo(
    () => filters?.endDate || submissionEndDate || today,
    [filters?.endDate, submissionEndDate]
  );

  return {
    defaultStartDate: selectedCase?.claimSubmissionStartDate,
    defaultEndDate: submissionEndDate,
    startDate,
    endDate,
  };
};
