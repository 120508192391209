import React, { useMemo } from 'react';
import { useUserOrgCase } from '@context/UserOrgCaseContext';
import {
  Box,
  ClaimscoreLogoSmall,
  DashboardIcon,
  Flags,
  MenuOpenIcon,
  NavItem,
  Stack,
  SupervisorAccountIcon,
  useBreakpoints,
  useFlag,
} from 'ui';
import { ClaimscoreLogoLarge } from 'ui/src/components/Icons/ClaimscoreLogoLarge';
import { ColorPaper200 } from 'ui/src/design-system/colors';
import { SIDENAVBAR_CONTENT_WRAPPER } from './SideNavbar.testIds';
import { useLocation } from 'react-router-dom';

const listBoxStyles = {
  mt: 3,
};

const logoBoxStyles = {
  pl: 1,
};
const sidebarBoxStyles = {
  display: 'flex',
  gap: 2,
  mt: 2,
};

const menuOpenIconStyles = {
  cursor: 'pointer',
};

const SIDE_NAVBAR_ITEMS = [
  {
    text: 'Dashboard',
    icon: <DashboardIcon />,
    to: `/dashboard`,
  },
];

const SIDE_NAVBAR_WITH_PERMISSIONS_ITEMS = [
  ...SIDE_NAVBAR_ITEMS,
  {
    text: 'Permissions',
    icon: <SupervisorAccountIcon />,
    to: `/permissions/organization`,
  },
];

export interface MainSideNavbarContentProps {
  open?: boolean;
  onClose?: () => void;
}

export const MainSideNavbarContent = ({
  open,
  onClose,
}: MainSideNavbarContentProps) => {
  const hasAccessAndRoleManagement = useFlag(Flags.accessAndRoleManagement);
  const { isLgUp } = useBreakpoints();
  const { isAdminOrOwnerInAnyOrgOrCase } = useUserOrgCase();
  const { search } = useLocation();

  const getSideNavbarBoxContent = useMemo(() => {
    if (open) {
      return (
        <Box sx={logoBoxStyles}>
          <ClaimscoreLogoLarge color={ColorPaper200} />
          {!isLgUp && (
            <MenuOpenIcon
              onClick={onClose}
              style={menuOpenIconStyles}
              aria-label="menu-open-icon"
            />
          )}
        </Box>
      );
    }
    return (
      <Box sx={logoBoxStyles}>
        <ClaimscoreLogoSmall color={ColorPaper200} />;
      </Box>
    );
  }, [open, isLgUp, onClose]);

  const getSideNavbarItems = useMemo(() => {
    const sideNavbarItems =
      hasAccessAndRoleManagement && isAdminOrOwnerInAnyOrgOrCase
        ? SIDE_NAVBAR_WITH_PERMISSIONS_ITEMS
        : SIDE_NAVBAR_ITEMS;

    return sideNavbarItems.map(({ text, icon, to }) => (
      <NavItem
        kind="dark"
        key={text}
        icon={icon}
        label={text}
        to={`${to}${search}`}
      />
    ));
  }, [hasAccessAndRoleManagement, isAdminOrOwnerInAnyOrgOrCase, search]);

  return (
    <Stack data-testid={SIDENAVBAR_CONTENT_WRAPPER}>
      <Box sx={sidebarBoxStyles}>{getSideNavbarBoxContent}</Box>
      <Box sx={listBoxStyles}>{getSideNavbarItems}</Box>
    </Stack>
  );
};
