import React from 'react';
import { Box as BoxMui, BoxProps as BoxPropsMui } from '@mui/material';

export type BoxProps = Pick<
  BoxPropsMui,
  | 'children'
  | 'sx'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'width'
  | 'height'
  | 'display'
  | 'mb'
  | 'gap'
  | 'flexWrap'
  | 'flexDirection'
  | 'justifyContent'
  | 'flexGrow'
>;

export const Box = ({ ...rest }: BoxProps) => <BoxMui {...rest} />;
