import React, {
  useContext,
  useMemo,
  ChangeEventHandler,
  useState,
} from 'react';
import {
  Box,
  Field,
  Stack,
  styled,
  useBreakpoints,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  CasesIcon,
  OrganizationIcon,
  SelectDrawer,
} from 'ui';
import { PageToolbarPortal } from '@components/PageHeaderToolbar/PageHeaderToolbar';
import { UserOrgCaseContext } from '@context/UserOrgCaseContext';
import { LayoutContext } from '@context/LayoutContext';
import { useNavigate } from 'react-router-dom';

const StyledHeader = styled(Stack)`
  position: fixed;
  padding: ${({ theme }) => theme.spacing(2)};
  padding-left: 86px;
  height: 88px;
  background: ${({ theme }) => theme.palette.paper[200]};
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: 1000;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 100%;
  margin-top: 56px;
`;

const SpeedDialWrapper = styled(Box)`
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 9999;
`;

export const SecondaryTopNavbar = () => {
  const {
    userOrganizations,
    userCases,
    organizationSelected,
    casePermissionsSelected,
    handleOrganizationSelect,
    handleCaseSelect,
  } = useContext(UserOrgCaseContext);
  const navigate = useNavigate();
  const { isSpeedDialOpen, handleSpeedDialClose, handleSpeedDialOpen } =
    useContext(LayoutContext);
  const [isOrganizationsDrawerOpen, setIsOrganizationsDrawerOpen] =
    useState(false);
  const [isCasesDrawerOpen, setIsCasesDrawerOpen] = useState(false);
  const { isLgUp } = useBreakpoints();
  const isPermissionsUrl = location.pathname.includes('permissions');
  const isHistoryUrl = location.pathname.includes('history');

  const organizationItems = useMemo(
    () =>
      userOrganizations.map(({ organizationID, organizationName }) => ({
        key: organizationID,
        value: organizationName,
      })),
    [userOrganizations]
  );

  const caseItems = useMemo(
    () =>
      userCases.map(({ caseID, caseName }) => ({
        key: caseID,
        value: caseName,
      })),
    [userCases]
  );

  const handleOrganizationChange: ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const selectedOrgId = e.target.value;
    const selectedOrg = userOrganizations.find(
      ({ organizationID }) => organizationID === selectedOrgId
    );
    if (selectedOrg) {
      if (isPermissionsUrl) {
        navigate(`/permissions/organization${location.search}`);
      }
      handleOrganizationSelect(selectedOrg);
    }
  };

  const handleCaseChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedCaseId = e.target.value;
    const selectedCase = userCases.find(
      ({ caseID }) => caseID === selectedCaseId
    );
    if (selectedCase) {
      if (isPermissionsUrl) {
        navigate(`/permissions/case${location.search}`);
      }
      handleCaseSelect(selectedCase);
    }
  };

  if (isLgUp) {
    return (
      <StyledHeader
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        data-testid="secondary-header-container"
      >
        <Box>
          <Field
            sx={{ width: '256px', marginRight: 2 }}
            type="select"
            label="Organization"
            value={organizationSelected?.organizationID || ''}
            menuItems={organizationItems || []}
            onChange={handleOrganizationChange}
          />
          <Field
            sx={{ width: '256px' }}
            type="select"
            label="Case"
            value={casePermissionsSelected?.caseID || ''}
            menuItems={caseItems || []}
            onChange={handleCaseChange}
            disabled={userCases.length === 0 || isHistoryUrl}
          />
        </Box>
        <Box>
          <PageToolbarPortal />
        </Box>
      </StyledHeader>
    );
  }

  return (
    <SpeedDialWrapper>
      <SpeedDial
        ariaLabel="Utilities"
        icon={<SpeedDialIcon />}
        open={isSpeedDialOpen}
        onOpen={handleSpeedDialOpen}
        onClose={handleSpeedDialClose}
      >
        <SpeedDialAction
          icon={<OrganizationIcon />}
          tooltipTitle="Organization"
          onClick={() => {
            setIsOrganizationsDrawerOpen(true);
          }}
        />
        {!isHistoryUrl && (
          <SpeedDialAction
            icon={<CasesIcon />}
            open={isSpeedDialOpen}
            tooltipTitle="Case"
            onClick={() => {
              setIsCasesDrawerOpen(true);
            }}
          />
        )}
      </SpeedDial>
      <SelectDrawer
        label="Organization"
        isOpen={isOrganizationsDrawerOpen}
        handleOpen={() => setIsOrganizationsDrawerOpen(true)}
        handleClose={() => {
          setIsOrganizationsDrawerOpen(false);
        }}
        value={organizationSelected?.organizationID || ''}
        options={organizationItems || []}
        onChange={(key) => {
          handleOrganizationChange({
            target: { value: key },
          } as unknown as React.ChangeEvent<HTMLInputElement>);
          setIsOrganizationsDrawerOpen(false);
        }}
      />
      <SelectDrawer
        label="Cases"
        isOpen={isCasesDrawerOpen}
        handleOpen={() => setIsCasesDrawerOpen(true)}
        handleClose={() => {
          setIsCasesDrawerOpen(false);
        }}
        value={casePermissionsSelected?.caseID || ''}
        options={caseItems || []}
        onChange={(key) => {
          handleCaseChange({
            target: { value: key },
          } as unknown as React.ChangeEvent<HTMLInputElement>);
          setIsCasesDrawerOpen(false);
        }}
      />
    </SpeedDialWrapper>
  );
};
