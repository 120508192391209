import * as React from 'react';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/material';
import { styled } from '../../utils/styled';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

const StyledAlert = styled(Alert)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: none;
  border-left: ${({ theme }) => theme.borderRadii.outer} solid
    ${({ severity, theme }) => severity && theme.palette[severity].main};
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  padding: 22px 24px 22px 16px;
  width: 500px;
  align-items: center;

  .MuiAlert-message,
  .MuiButtonBase-root,
  .MuiAlert-icon {
    padding: 0;
  }
`;

export type ToastType = 'success' | 'info' | 'warning' | 'error';
export interface ToastProps {
  message: string;
  type: ToastType;
  open: boolean;
  onClose: () => void;
}

export const Toast = ({
  message,
  type = 'info',
  open,
  onClose,
}: ToastProps) => (
  <Box sx={{ width: '100%' }}>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <StyledAlert
        variant="outlined"
        severity={type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        <Typography variant="body">{message}</Typography>
      </StyledAlert>
    </Snackbar>
  </Box>
);
