import React, { Ref, forwardRef } from 'react';
import { Button } from '../Button';
import { ButtonProps } from '../Button/Button';
import { styled } from '../../utils/styled';

const StyledActionButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral[200]}`,
  color: theme.palette.neutral[1000],
  height: '56px',
  minWidth: '56px',
}));

export const ActionButton = forwardRef(
  (
    { label, startIcon, ...props }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <StyledActionButton
      ref={ref}
      variant="outlined"
      color="neutral"
      startIcon={startIcon}
      label={label}
      {...props}
    />
  )
);
