import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';

type NavigationHistoryContextType = {
  navigationHistory: string[];
  previousPage: string;
};

const NavigationHistoryContext = createContext<
  NavigationHistoryContextType | undefined
>(undefined);

export const NavigationHistoryProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const [navigationHistory, setNavigationHistory] = useState<
    NavigationHistoryContextType['navigationHistory']
  >([]);

  useEffect(() => {
    setNavigationHistory((prev) => {
      if (prev[prev.length - 1] !== location.pathname) {
        return [...prev, location.pathname];
      }
      return prev;
    });
  }, [location]);

  const context = useMemo(
    () => ({
      navigationHistory,
      previousPage: navigationHistory[navigationHistory.length - 2],
    }),
    [navigationHistory]
  );

  return (
    <NavigationHistoryContext.Provider value={context}>
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export const useNavigationHistory = () => {
  const context = useContext(NavigationHistoryContext);
  if (!context) {
    throw new Error(
      'useNavigationHistory must be used within a NavigationHistoryProvider'
    );
  }
  return context;
};
