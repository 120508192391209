import React from 'react';
import { Card } from '@mui/material';
import { styled } from '../../utils/styled';
import { numberFormatterWithUnits } from '../../utils/number-formatter';
import { ArrowUpwardIcon } from '../Icons/ArrowUpwardIcon';
import {
  ColorGreen600,
  ColorRed600,
  ColorYellow600,
} from '../../design-system/colors';
import { Divider } from '../Divider';
import { ThumbsUpIcon } from '../Icons/ThumbsUpIcon';
import { WatchLaterIcon } from '../Icons/WatchLaterIcon';
import { ThumbsDownIcon } from '../Icons/ThumbsDownIcon';
import { Typography } from '../Typography';
import { Stack } from '../Stack';
import { Box } from '../Box';

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.spacing(1)};
  align-items: flex-start;
  width: 552px;
  height: 495px;
`;
export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;
export const iconsList = [
  <ThumbsUpIcon color={ColorGreen600} />,
  <WatchLaterIcon color={ColorYellow600} />,
  <ThumbsDownIcon color={ColorRed600} />,
];

export interface SpikeContentProps {
  totalClaims: number;
  VSaverage: number;
  claimData?: { key: string; value: number }[];
}

export const SpikeContent = ({
  totalClaims,
  VSaverage,
  claimData,
}: SpikeContentProps) => (
  <Box sx={{ mb: 2 }}>
    <Stack width="100%" direction="row" mb={2}>
      <Stack flex={1} direction="column" alignItems="center">
        <Typography variant="h3">
          {totalClaims === 0
            ? '--'
            : `${numberFormatterWithUnits(totalClaims)}`}
        </Typography>
        <StyledTypography variant="caption">Total Claims</StyledTypography>
      </Stack>
      <Stack flex={1} direction="column" alignItems="center">
        <Typography variant="h3">
          {VSaverage === 0 ? (
            '--'
          ) : (
            <>
              <ArrowUpwardIcon color={ColorRed600} />
              {numberFormatterWithUnits(VSaverage)}%
            </>
          )}
        </Typography>
        <StyledTypography variant="caption">VS. Average</StyledTypography>
      </Stack>
    </Stack>
    <Stack
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" light flexItem />}
      width="100%"
      justifyContent="center"
    >
      {claimData?.map((item, index: number) => (
        <Stack
          flex={0}
          key={`${index}-claim-data`}
          direction="column"
          data-testid="claim-data"
          sx={{ alignItems: 'center' }}
        >
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            {iconsList[index]}
            <Typography sx={{ ml: 0 }} variant="subtitle">
              {item.value === 0 ? 'N/A' : `${item.value}%`}
            </Typography>
          </Stack>
          <StyledTypography variant="caption">{item.key}</StyledTypography>
        </Stack>
      ))}
    </Stack>
  </Box>
);
