// TODO: WIP
// function escapeRegExp(string: string): string {
//   return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
// }

// function generateRouteRegex(...args: string[]): RegExp {
//   const escapedCaseWord = escapeRegExp(args[0]);
//   const escapedClaimsWord = escapeRegExp(args[1]);
//   return new RegExp(
//     `/${escapedCaseWord}/[^/]+/${claimsWord}/[^/]+`
//   );
// }

// export const getRouteLabel = (route: string) => {
//   if (!route) return undefined;
// };

export const PATH_VARIABLES = {
  caseId: ':caseId',
  claimId: ':claimId',
};

const PUBLIC = {
  // NO LAYOUT
  pageNotFound: '*',
  login: '/login',
  signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset',
  auth: '/_auth',
  entryway: '/entryway',
  emailVerification: '/email-verification',
  terms: '/terms',
  privacyPolicy: '/privacy',
};

const PRIVATE_BASE = {
  // TOP NAVBAR WITH CONTROLS ONLY
  myCases: '/',
  caseNew: '/new-case',
  superAdminAccounts: '/admin/accounts',
  superAdminCases: '/admin/users',
  dashboard: '/dashboard',
};

const CASE_BASE = {
  // FULL LAYOUT
  caseDashboard: (caseId = PATH_VARIABLES.caseId) =>
    `/case/${caseId}/dashboard`,
  caseSettings: (caseId = PATH_VARIABLES.caseId) => `/case/${caseId}/settings`,
  casePermissions: (caseId = PATH_VARIABLES.caseId) =>
    `/case/${caseId}/permissions`,
  caseReports: (caseId = PATH_VARIABLES.caseId) => `/case/${caseId}/reports`,
  caseNotice: (caseId = PATH_VARIABLES.caseId) => `/case/${caseId}/notice`,
  caseForm: (caseId = PATH_VARIABLES.caseId) => `/case/${caseId}/form`,
  caseInstructions: (caseId = PATH_VARIABLES.caseId) =>
    `/case/${caseId}/instructions`,
  proofOfPurchase: (caseId = PATH_VARIABLES.caseId) =>
    `/case/${caseId}/proof-of-purchase-claims`,
  proofOfPurchaseDetails: (
    caseId = PATH_VARIABLES.caseId,
    claimId = PATH_VARIABLES.claimId
  ) => `/case/${caseId}/proof-of-purchase-claims/${claimId}`,
};

export const PATHS = {
  PUBLIC,
  PRIVATE_BASE,
  CASE_BASE,
};
