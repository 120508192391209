import React from 'react';
import {
  SpeedDial as SpeedDialMui,
  SpeedDialProps as SpeedDialPropsMui,
  styled,
} from '@mui/material';

export type SpeedDialProps = SpeedDialPropsMui;

const StyledSpeedDial = styled(SpeedDialMui)`
  .MuiSpeedDial-actions {
    align-items: flex-end;
  }
  .MuiFab-root {
    align-self: flex-end;
  }
`;

export const SpeedDial = ({ ...rest }: SpeedDialProps) => (
  <StyledSpeedDial {...rest} />
);
