import React from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  hasAccess: boolean;
  children: React.ReactElement;
}

export const ProtectedRoute = ({
  hasAccess,
  children,
}: ProtectedRouteProps) => {
  if (!hasAccess) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};
