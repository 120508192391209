import React from 'react';
import { List as ListMui, ListProps as ListPropsMui } from '@mui/material';

export type ListProps = ListPropsMui;

export const List = React.forwardRef(
  ({ ...rest }: ListProps, ref: React.ForwardedRef<HTMLUListElement>) => (
    <ListMui {...rest} ref={ref} />
  )
);
