import React, { useContext, useMemo, useRef, useState } from 'react';
import { ColorGreen200 } from 'ui/src/design-system/colors';
import {
  Button,
  LogoutIcon,
  useBreakpoints,
  MenuIcon,
  Typography,
  PopoverMenu,
  ElementWithBadge,
  styled,
  TopNavbar,
} from 'ui';
import { AuthContext } from '@context/AuthContext';
import { PageHeaderPortal } from '@components/PageHeaderTitle/PageHeaderTitle';
import { useLocation, useSearchParams } from 'react-router-dom';

const StyledNavbar = styled(TopNavbar, {
  shouldForwardProp: (prop) => prop !== 'hasSidebar',
})<{ hasSidebar?: boolean }>`
  box-shadow: 0px 0px 0px rgba(100, 116, 139, 0.16);
  width: ${({ theme, hasSidebar }) =>
    hasSidebar
      ? `calc(100% - ${theme.constants.navigationDrawerMinimizedWidth})`
      : undefined};
`;

interface MainTopNavbarProps {
  toggleSidebar?: () => void;
}

const SIDE_MENU_BTN_LABEL = 'Open Side Navigation Drawer';

export const MainTopNavbar = ({ toggleSidebar }: MainTopNavbarProps) => {
  const { isLgUp } = useBreakpoints();
  const { pathname } = useLocation();
  const { user, logout } = useContext(AuthContext);
  const [, setSearchParams] = useSearchParams();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const anchorEl = useRef<HTMLDivElement>(null);

  const hasSideBar = useMemo(
    () => isLgUp && pathname !== '/',
    [isLgUp, pathname]
  );

  const shouldShowSideMenuButton = useMemo(
    () => !isLgUp && pathname !== '/',
    [isLgUp, pathname]
  );

  const getLeftToolbar = () => (
    <>
      {shouldShowSideMenuButton && (
        <Button
          color="neutral"
          onClick={toggleSidebar}
          sx={{ marginRight: 1 }}
          startIcon={
            <MenuIcon
              aria-label={SIDE_MENU_BTN_LABEL}
              title={SIDE_MENU_BTN_LABEL}
            />
          }
          variant="text"
          aria-label="Open Side Navigation Drawer"
        />
      )}
      <PageHeaderPortal />
    </>
  );

  const getRightToolbar = () => (
    <>
      <Button
        color="neutral"
        aria-label="User settings"
        variant="text"
        onClick={() => {
          setIsPopoverOpen((prev) => !prev);
        }}
        startIcon={
          <ElementWithBadge
            element="avatar"
            badgeColor="error"
            ref={anchorEl}
            name={user?.displayName || 'Claim Score'}
            size="small"
            color={ColorGreen200}
          />
        }
      />

      <PopoverMenu
        anchorEl={anchorEl.current}
        isOpen={isPopoverOpen}
        onClose={() => {
          setIsPopoverOpen(false);
        }}
        menuItems={[
          {
            onClick: () => {
              logout();
              setSearchParams();
            },
            title: 'Sign out',
            icon: (
              <Typography variant="body" color="error">
                <LogoutIcon />
              </Typography>
            ),
          },
        ]}
      />
    </>
  );

  return (
    <StyledNavbar
      hasSidebar={hasSideBar}
      logoVariant="none"
      leftToolbar={getLeftToolbar()}
      rightToolbar={getRightToolbar()}
    />
  );
};
