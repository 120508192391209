import React, { ReactNode, useState } from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { StyledField, StyledIconWrapper, StyledMenuItem } from './Field.styles';
import { CurrencyField } from './CurrencyField';
import { IconButton } from '../IconButton';
import { ExpandMoreIcon } from '../Icons/ExpandMoreIcon';
import { Kind } from '../../types/kind';
import { DollarSignIcon } from '../Icons/DollarSignIcon';
import { WithRequired } from '../../utils/withRequired';
import { VisibilityOffIcon } from '../Icons/VisibilityOffIcon';
import { VisibilityIcon } from '../Icons/VisibilityIcon';

export type FieldTypes = 'text' | 'currency' | 'select' | 'password';

export interface FieldProps
  extends Pick<
    WithRequired<TextFieldProps, 'onChange'>,
    | 'label'
    | 'disabled'
    | 'onChange'
    | 'value'
    | 'name'
    | 'onBlur'
    | 'sx'
    | 'inputProps'
  > {
  type: FieldTypes;
  kind?: Kind;
  value: string;
  iconLeft?: ReactNode;
  menuItems?: { key: string; value: string; component?: ReactNode }[];
  errorMsg?: string;
}

export const Field = ({
  type,
  kind = 'light',
  menuItems,
  iconLeft,
  errorMsg,
  ...rest
}: FieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  switch (type) {
    case 'password':
      return (
        <StyledField
          kind={kind}
          error={Boolean(errorMsg)}
          helperText={errorMsg}
          fullWidth
          label="Password"
          id="password"
          margin="normal"
          name="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      );
    case 'text':
      return (
        <StyledField
          error={Boolean(errorMsg)}
          helperText={errorMsg}
          fullWidth
          kind={kind}
          InputProps={{
            startAdornment: <StyledIconWrapper>{iconLeft}</StyledIconWrapper>,
          }}
          {...rest}
        />
      );
    case 'currency':
      return (
        <CurrencyField
          kind={kind}
          fullWidth
          InputProps={{
            startAdornment: (
              <StyledIconWrapper>{iconLeft}</StyledIconWrapper>
            ) || <DollarSignIcon />,
          }}
          {...rest}
        />
      );
    case 'select':
      return (
        <StyledField
          error={Boolean(errorMsg)}
          helperText={errorMsg}
          select
          fullWidth
          SelectProps={{ IconComponent: ExpandMoreIcon }}
          kind={kind}
          InputProps={{
            startAdornment: <StyledIconWrapper>{iconLeft}</StyledIconWrapper>,
          }}
          {...rest}
        >
          {menuItems?.map(({ key, value, component }) => (
            <StyledMenuItem value={key} key={`menuitem-${value}${key}`}>
              {component || value}
            </StyledMenuItem>
          ))}
        </StyledField>
      );
    default:
      throw new Error('Invalid prop value for `type`.');
  }
};
