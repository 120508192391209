import React, { forwardRef, Ref } from 'react';
import { Card as CardMui, CardProps as CardPropsMui } from '@mui/material';

export type CardProps = Pick<CardPropsMui, 'children' | 'sx' | 'onClick'>;

export const Card = forwardRef(
  ({ ...rest }: CardProps, ref: Ref<HTMLDivElement>) => (
    <CardMui ref={ref} {...rest} />
  )
);
