import React from 'react';
import { styled } from '../../utils/styled';
import { Divider } from '../Divider';
import { numberFormatter } from '../../utils/number-formatter';
import { Box } from '../Box';
import { Typography } from '../Typography';
import { Stack } from '../Stack';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
`;
export const StyledStack = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.text.primaryInverted};
`;

export interface SpikeInfoBlockProps {
  title: string;
  info: { value: number; link: string }[];
}

export const SpikeInfoBlock = ({ info, title }: SpikeInfoBlockProps) => (
  <Box sx={{ mb: 2 }}>
    <Box sx={{ mb: 1 }}>
      <Typography variant="subtitle">{title}</Typography>
    </Box>
    <StyledStack width="100%" direction="column" spacing={1}>
      {info.map((item, index: number) => (
        <Stack
          direction="row"
          key={`${index}-spike-blocks`}
          spacing={1}
          flex={0}
          divider={<Divider orientation="vertical" light flexItem />}
          sx={{ p: 0 }}
          justifyContent="flex-start"
          data-testid="spike-info-block"
        >
          <StyledTypography variant="body" sx={{ width: '48px' }}>
            {numberFormatter.format(item.value)}
          </StyledTypography>
          <StyledTypography>{item.link}</StyledTypography>
        </Stack>
      ))}
    </StyledStack>
  </Box>
);
