import {
  LDClient,
  asyncWithLDProvider,
  useLDClient,
} from 'launchdarkly-react-client-sdk';

export { useLDClient } from 'launchdarkly-react-client-sdk';

export enum Flags {
  accessAndRoleManagement = 'access-and-role-management',
  layoutRevamp = 'layout-revamp',
  reports = 'reports',
}

type User = {
  id?: string;
  email?: string | null;
};

export async function setupFeatureFlag(
  launchdarklyClient: LDClient,
  user: User | null,
  organizationName?: string,
  caseId?: string
) {
  if (launchdarklyClient && user?.email) {
    await launchdarklyClient.identify({
      kind: 'user',
      key: String(user.id),
      email: user.email,
      custom: {
        activeOrganization: organizationName,
        activeCase: caseId,
      },
    });
  }
}

export const useFlag = (name: Flags) => {
  const ldClient = useLDClient();
  return ldClient?.variation(name, false);
};

export const initializeLaunchDarkly = async (clientID: string) =>
  asyncWithLDProvider({
    clientSideID: clientID,
  });
