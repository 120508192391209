import React from 'react';
import { getTotal } from './utils';
import { styled } from '../../utils/styled';
import { PercentageBar } from '../PercentageBar';
import { Card } from '../Card';
import {
  currencyFormatterInteger,
  numberFormatter,
} from '../../utils/number-formatter';
import { Typography } from '../Typography';
import { Stack } from '../Stack';

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'borderColor',
})<{ borderColor: string }>`
  padding: ${({ theme }) => theme.spacing(2)};
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  border-left-style: solid;
  border-left-width: ${({ theme }) => theme.spacing(1)};
  border-left-color: ${({ borderColor }) => borderColor};
  width: 100%;
  height: 134px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

interface CardProps {
  title: string;
  borderColor: string;
  items: { key: string; value: number }[];
  format?: 'number' | 'currency';
}

export const StatsCard = ({
  title,
  borderColor,
  items,
  format = 'number',
}: CardProps) => {
  const totalNum = getTotal(items);
  const formatter =
    format === 'number' ? numberFormatter : currencyFormatterInteger;

  return (
    <StyledCard borderColor={borderColor}>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 0 }}
      >
        <Typography variant="h5">{title}</Typography>
        <Typography
          variant="body"
          sx={{ color: 'text.secondary', alignItems: 'flex-end' }}
        >
          {formatter.format(
            !(format === 'currency') ? totalNum : totalNum / 100
          )}{' '}
          Total
        </Typography>
      </Stack>
      <PercentageBar items={items} format={format} />
    </StyledCard>
  );
};
