import React from 'react';
import {
  SwipeableDrawer as SwipeableDrawerMui,
  SwipeableDrawerProps as SwipeableDrawerPropsMui,
} from '@mui/material';
import { styled } from '../../utils/styled';

export interface SwipeableDrawerProps extends SwipeableDrawerPropsMui {
  isSmUp?: boolean;
}

export const StyledSwipeableDrawer = styled(SwipeableDrawerMui, {
  shouldForwardProp: (prop) => prop !== 'isSmUp',
})<{ isSmUp?: boolean }>`
  .MuiModal-backdrop {
    background-color: #fff0;
  }

  .MuiDrawer-paper {
    width: ${({ isSmUp }) => (isSmUp ? '600px' : '100vw')};
    height: ${({ isSmUp }) => (isSmUp ? 'calc(100vh - 144px)' : undefined)};
    border-top: 0;
    border-top-right-radius: ${({ theme, isSmUp }) =>
      !isSmUp ? theme.borderRadii.mobileDrawer : 0};
    border-top-left-radius: ${({ theme, isSmUp }) =>
      !isSmUp ? theme.borderRadii.mobileDrawer : 0};
    top: ${({ isSmUp }) => (!isSmUp ? undefined : '145px')};
    box-shadow: ${({ theme }) => theme.boxShadow};
    border-left: 0;
  }
`;

export const SwipeableDrawer = ({
  children,
  ...rest
}: SwipeableDrawerProps) => (
  <StyledSwipeableDrawer {...rest}>{children}</StyledSwipeableDrawer>
);
