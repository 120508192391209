import React from 'react';
import {
  Radio as RadioUi,
  FormControlLabel,
  RadioProps as RadioPropsMui,
} from '@mui/material';
import { styled } from '../../utils/styled';
import { ColorNeutral300, ColorNeutral600 } from '../../design-system/colors';
import { Typography } from '../Typography';

const StyledRadioUi = styled(RadioUi)`
  &.Mui-checked:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export interface RadioProps
  extends Pick<RadioPropsMui, 'name' | 'checked' | 'onClick'> {
  label: string;
  disabled?: boolean;
  value: string;
}
export const Radio = ({ disabled, value, label, ...props }: RadioProps) => (
  <FormControlLabel
    disabled={disabled}
    value={value}
    aria-labelledby={`${label}-radio`}
    label={
      <Typography
        color={disabled ? ColorNeutral300 : ColorNeutral600}
        variant="body"
      >
        {label}
      </Typography>
    }
    control={
      <StyledRadioUi
        {...props}
        id={`${label}-radio`}
        inputProps={{
          'aria-label': label,
        }}
      />
    }
  />
);
