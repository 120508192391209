import React, { ReactElement } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import { PopoverVirtualElement, styled } from '@mui/material';

const StyledPaper = styled(Paper)`
  width: 200px;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const StyledMenuList = styled(MenuList)`
  padding: 0px;
`;

type ListItem = {
  onClick: () => void;
  title: string;
  icon?: ReactElement;
};

interface PopoverMenuProps {
  anchorEl: HTMLElement | PopoverVirtualElement | null;
  isOpen: boolean;
  onClose: () => void;
  menuItems: ListItem[];
}

export const PopoverMenu = ({
  anchorEl,
  isOpen,
  onClose,
  menuItems,
}: PopoverMenuProps) => (
  <Popper
    open={isOpen}
    anchorEl={anchorEl}
    placement="bottom-start"
    transition
    disablePortal
  >
    {({ TransitionProps }) => (
      <Grow
        {...TransitionProps}
        style={{
          transformOrigin: 'right top',
        }}
      >
        <StyledPaper>
          <ClickAwayListener onClickAway={onClose}>
            <StyledMenuList
              autoFocusItem={isOpen}
              id="composition-menu"
              aria-labelledby="composition-button"
            >
              {menuItems.map(({ onClick, title, icon }, i) => (
                <MenuItem key={i} onClick={onClick}>
                  <ListItemText>{title}</ListItemText>
                  {icon}
                </MenuItem>
              ))}
            </StyledMenuList>
          </ClickAwayListener>
        </StyledPaper>
      </Grow>
    )}
  </Popper>
);
