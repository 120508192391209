import React, { Suspense, useContext, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {
  ErrorBoundary,
  NavigationHistoryProvider,
  useBreakpoints,
  styled,
  ClaimScoreLoader,
  Divider,
} from 'ui';
import { PATHS } from '@utils/paths';
import { ErrorBoundaryPage } from '@pages/ErrorBoundaryPage/ErrorBoundaryPage';
import { LayoutProvider } from '@context/LayoutContext';
import { AuthContext } from '@context/AuthContext';
import { UserOrgCaseProvider } from '@context/UserOrgCaseContext';
import { SideNavbar } from './components/SideNavbar/SideNavbar';
import { MainTopNavbar } from './components/TopNavbar/MainTopNavbar';
import { SecondaryTopNavbar } from './components/TopNavbar/SecondaryTopNavbar';

const DashboardLayoutRoot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasSidebar',
})<{ hasSidebar?: boolean }>`
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: fixed;
`;

const StyledMain = styled('main')`
  width: 100%;
  height: fit-content;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(3)}`};
  padding-top: 88px;
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    padding-top: 176px;
  }
`;
const MainWrapper = styled('main')`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.lg}px`}) {
    margin-left: 84px;
  }
`;

interface PrivateLayoutProps {
  withSidebar?: boolean;
  withNavbar?: boolean;
}

export const PrivateLayout = ({
  withSidebar,
  withNavbar,
}: PrivateLayoutProps) => {
  const { isXlUp } = useBreakpoints();
  const { isAuthenticated } = useContext(AuthContext);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const handleOpenSidebar = () => {
    setSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setSidebarOpen(false);
  };

  const handleChangeSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  if (!isAuthenticated) {
    return <Navigate to={PATHS.PUBLIC.login} />;
  }

  return (
    <NavigationHistoryProvider>
      <LayoutProvider>
        <UserOrgCaseProvider>
          <div style={{ display: isXlUp ? 'flex' : 'block' }}>
            {withSidebar && (
              <SideNavbar
                open={isSidebarOpen}
                onClose={handleCloseSidebar}
                onMouseOver={handleOpenSidebar}
              />
            )}
            <DashboardLayoutRoot hasSidebar={withSidebar}>
              {withNavbar && (
                <>
                  <MainTopNavbar toggleSidebar={handleChangeSidebar} />
                  <Divider orientation="horizontal" noMargin />
                  <SecondaryTopNavbar />
                </>
              )}
              <ErrorBoundary fallback={<ErrorBoundaryPage />}>
                <Suspense fallback={<ClaimScoreLoader />}>
                  <MainWrapper>
                    <StyledMain>
                      <Outlet />
                    </StyledMain>
                  </MainWrapper>
                </Suspense>
              </ErrorBoundary>
            </DashboardLayoutRoot>
          </div>
        </UserOrgCaseProvider>
      </LayoutProvider>
    </NavigationHistoryProvider>
  );
};
