export enum Determination {
  approved = 'approved',
  pending = 'pending',
  rejected = 'rejected',
}
export interface Range {
  min?: number;
  max?: number;
}

export type DateRange = {
  startDate: string;
  endDate: string;
};

export interface Paginated<T> {
  page: T[];
  totalPages: number;
}

export interface DashboardFilters {
  dateRange: {
    startDate: string;
    endDate: string;
  };
  determinations?: Determination[];
  unitRange?: Range;
  payoutRange?: Range;
  scoreRange?: Range;
  deductionCodes?: string[];
  claimTypes?: ClaimTypes[];
  timeInterval?: TimeInterval;
}

export interface DeterminationFilters {
  startDate?: string;
  endDate?: string;
  eligibleUnitRangeMin?: number;
  eligibleUnitRangeMax?: number;
  payoutAmountRangeMin?: number;
  payoutAmountRangeMax?: number;
  scoreRangeMin?: number;
  scoreRangeMax?: number;
  acceptedDeductionCodes?: string[];
  determinationFilters?: string[];
}

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Reviewer = 'reviewer',
  Viewer = 'viewer',
  Developer = 'developer',
}

export enum ApprovalStatus {
  Approved = 'approved',
  Pending = 'pending',
  Denied = 'denied',
}

export enum TimeRange {
  Today = 'today',
  LastWeek = 'last_7_days',
  LastMonth = 'last_30_days',
}

export enum ClaimTypes {
  direct_notice = 'direct_notice',
  pop = 'pop',
  non_pop = 'non_pop',
}

export enum TimeInterval {
  Hour = 'hour',
  FourHourBlock = 'fourHourBlock',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
}
