import React, { ReactNode, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AccordionDetails, Typography, useTheme } from '@mui/material';
import {
  AccordionInnerItems,
  AccordionLink,
  ItemWrapper,
  NavItemWrapper,
  StyledAccordion,
  StyledAccordionSummary,
} from './NavItem.styles';
import { ChevronDownIcon } from '../Icons/ChevronDownIcon';
import { Kind } from '../../types/kind';
import { Badge } from '../Badge';
import { Box } from '../Box';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

type ItemProps = {
  label?: string;
  active?: boolean;
  icon?: ReactNode;
  counter?: number;
};

const Item = ({ icon, label, counter, active }: ItemProps) => {
  const theme = useTheme();
  const color = active
    ? theme.palette.green[400]
    : theme.palette.text.secondaryInverted;

  return (
    <ItemWrapper
      direction="row"
      alignItems="center"
      sx={{
        pl: 1,
        color,
        height: '50px',
      }}
    >
      <Box
        sx={{
          marginRight: 3,
          color,
        }}
      >
        {icon}
      </Box>
      {label && <Typography variant="bodyStrong">{label}</Typography>}
      {counter && (
        <Box>
          <Badge color="error" count={counter} size="large" />
        </Box>
      )}
    </ItemWrapper>
  );
};

type NavItemProps = {
  kind: Kind;
  label?: string;
  to?: string;
  counter?: number;
  icon?: ReactNode;
  subItems?: Omit<
    WithRequired<NavItemProps & ItemProps, 'to'>,
    'subItems' | 'kind'
  >[];
  onClick?: () => void;
};

export const NavItem = ({
  kind,
  label,
  to,
  icon,
  subItems,
  counter,
  onClick,
}: NavItemProps) => {
  const location = useLocation();
  const [accordionOpen, setAccordionOpen] = useState(
    subItems?.some((item) => location.pathname.includes(item.to))
  );

  if (subItems?.length) {
    return (
      <StyledAccordion
        kind={kind}
        expanded={accordionOpen}
        onChange={() => setAccordionOpen((prev) => !prev)}
      >
        <StyledAccordionSummary
          kind={kind}
          expandIcon={<ChevronDownIcon title="Expand" aria-label="Expand" />}
        >
          <Item
            icon={icon}
            label={label}
            counter={
              !accordionOpen
                ? subItems.reduce(
                    (acc, curr) => acc + (curr?.counter || 0),
                    0
                  ) || undefined
                : undefined
            }
          />
        </StyledAccordionSummary>
        <AccordionDetails sx={{ mb: 0 }}>
          {subItems.map((item) => (
            <AccordionLink
              key={item.label}
              to={item.to}
              end
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {({ isActive }) => (
                <AccordionInnerItems kind={kind}>
                  <Item
                    active={isActive}
                    label={item.label}
                    counter={item.counter}
                  />
                </AccordionInnerItems>
              )}
            </AccordionLink>
          ))}
        </AccordionDetails>
      </StyledAccordion>
    );
  }

  if (!to) {
    throw new Error('You must provide a either `subItems` or `to` props.');
  }

  return (
    <NavItemWrapper kind={kind}>
      <NavLink to={to} end onClick={onClick}>
        {({ isActive }) => (
          <Item icon={icon} active={isActive} label={label} counter={counter} />
        )}
      </NavLink>
    </NavItemWrapper>
  );
};
