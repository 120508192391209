import React from 'react';
import {
  CardHeader as CardHeaderMui,
  CardHeaderProps as CardHeaderPropsMui,
} from '@mui/material';

export type CardHeaderProps = Pick<CardHeaderPropsMui, 'action' | 'title'>;

export const CardHeader = ({ ...rest }: CardHeaderProps) => (
  <CardHeaderMui {...rest} />
);
