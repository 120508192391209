import React, { ReactNode } from 'react';
import { Case } from 'services/ClaimscoreApiService/Cases/types';
import { styled } from '../../utils/styled';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { Typography } from '../Typography';
import { CleanButton } from '../../utils/clean-button';

const ListContainer = styled('div')`
  border-radius: ${({ theme }) => theme.borderRadii.outer};
  background: ${({ theme }) => theme.palette.neutral[900]};
`;

const StyledButton = styled(CleanButton)`
  overflow: hidden;
  :first-of-type {
    border-top-right-radius: ${({ theme }) => theme.borderRadii.outer};
    border-top-left-radius: ${({ theme }) => theme.borderRadii.outer};
  }

  :last-of-type {
    border-bottom-right-radius: ${({ theme }) => theme.borderRadii.outer};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadii.outer};
  }
`;

const ItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'hasIcon',
})<{ hasIcon?: boolean }>`
  padding: ${({ theme }) => theme.spacing(2)};
  &:hover {
    background: ${({ theme, hasIcon }) =>
      !hasIcon ? theme.palette.neutral[800] : undefined};
  }
`;

export interface CaseDisplayProps {
  cases: (Pick<Case, 'caseID' | 'caseName'> & {
    rightIcon?: ReactNode;
    onClick?: () => void;
  })[];
}

export const CaseDisplay = ({ cases }: CaseDisplayProps) => {
  const renderContent = ({
    caseID,
    caseName,
    rightIcon,
    onClick,
  }: (typeof cases)[0]) => (
    <ItemWrapper direction="row" hasIcon={Boolean(rightIcon)} key={caseID}>
      <Stack direction="column">
        <Typography
          title={caseName}
          noWrap
          variant="subtitle"
          sx={{
            maxWidth: rightIcon ? '156px' : '216px',
            color: 'text.primaryInverted',
            mb: (theme) => theme.spacing(0),
          }}
        >
          {caseName}
        </Typography>
      </Stack>
      {rightIcon && (
        <Button
          variant="text"
          sx={{ marginLeft: 'auto' }}
          startIcon={rightIcon}
          onClick={onClick}
        />
      )}
    </ItemWrapper>
  );

  return (
    <ListContainer>
      {cases.map(({ caseID, caseName, rightIcon, onClick }) =>
        rightIcon ? (
          renderContent({ caseID, caseName, rightIcon, onClick })
        ) : (
          <StyledButton
            key={caseID}
            type="button"
            style={{ cursor: rightIcon ? 'default' : 'pointer' }}
            onClick={!rightIcon ? onClick : undefined}
          >
            {renderContent({ caseID, caseName, rightIcon, onClick })}
          </StyledButton>
        )
      )}
    </ListContainer>
  );
};
