import React from 'react';
import {
  ButtonGroup as ButtonGroupMui,
  ButtonGroupProps as ButtonGroupPropsMui,
  styled,
} from '@mui/material';

export type ButtonGroupProps = ButtonGroupPropsMui;

const StyledButtonGroup = styled(ButtonGroupMui)`
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
  > button:last-child {
    flex: 1;
  }
`;

export const ButtonGroup = ({ ...rest }: ButtonGroupProps) => (
  <StyledButtonGroup {...rest} />
);
