import claimScoreApiServiceClient from '../client';
import toQueryParams from 'admin/src/utils/toQueryParams';
import {
  GetCaseAccountsResponse,
  GetCaseAggregateResultsPayload,
  GetCaseAggregateResultsResponse,
  GetCaseResponse,
  GetClaimDeterminationResultsPayload,
  GetClaimDeterminationResultsResponse,
} from './types';

export const getCaseById = async (caseId: string): Promise<GetCaseResponse> => {
  const { data } = await claimScoreApiServiceClient.get(`/cases/${caseId}`);
  return data;
};

export const getCaseAccounts = async (
  caseId: string,
  pageNumber: number,
  accountRole?: string,
  accountStatus?: string,
  search?: string
): Promise<GetCaseAccountsResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/cases/${caseId}/accounts?${toQueryParams({
      pageNumber,
      pageSize: 12,
      accountRole,
      accountStatus,
      search,
    })}`
  );

  return data;
};

export const getClaimDeterminationResults = async ({
  caseID,
  pageNumber,
  filters,
}: GetClaimDeterminationResultsPayload): Promise<GetClaimDeterminationResultsResponse> => {
  const {
    startDate,
    endDate,
    eligibleUnitRangeMin,
    eligibleUnitRangeMax,
    payoutAmountRangeMin,
    payoutAmountRangeMax,
    scoreRangeMin,
    scoreRangeMax,
    acceptedDeductionCodes,
    determinationFilters,
  } = filters;

  const { data } = await claimScoreApiServiceClient.get(
    `/cases/${caseID}/results?${toQueryParams({
      pageNumber,
      pageSize: 10,
      startDate,
      endDate,
      eligibleUnitRangeMin,
      eligibleUnitRangeMax,
      payoutAmountRangeMin,
      payoutAmountRangeMax,
      scoreRangeMin,
      scoreRangeMax,
      acceptedDeductionCodes,
      determinationFilters,
    })}`
  );

  return data;
};

export const getCaseAggregateResults = async ({
  caseID,
  filters,
}: GetCaseAggregateResultsPayload): Promise<GetCaseAggregateResultsResponse> => {
  const { data } = await claimScoreApiServiceClient.post(
    `/cases/${caseID}/aggregate`,
    { filters }
  );

  return data;
};
