import React from 'react';
import {
  SpeedDialIcon as SpeedDialIconMui,
  SpeedDialIconProps as SpeedDialIconPropsMui,
} from '@mui/material';

export type SpeedDialIconProps = SpeedDialIconPropsMui;

export const SpeedDialIcon = ({ ...rest }: SpeedDialIconProps) => (
  <SpeedDialIconMui {...rest} />
);
