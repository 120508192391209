export const enableMockServiceWorker = async () => {
  if (import.meta.env.VITE_APP_ENV !== 'test') {
    console.warn('Mock Service Worker is only enabled in test environment');
    return;
  }

  const { worker } = await import('./browser');

  worker.start({
    onUnhandledRequest(req, print) {
      if (req.url.includes('/api/v2')) {
        print.warning();
      }
    },
  });
};
