import React, { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import {
  LogoWrapper,
  RightToolbarContainer,
  TopNavbarToolbar,
  TopNavbarWrapper,
} from './TopNavbar.styles';
import { ClaimscoreLogoLarge } from '../Icons/ClaimscoreLogoLarge';
import { ClaimscoreLogoSmall } from '../Icons/ClaimscoreLogoSmall';

export interface TopNavBarProps {
  logoVariant: 'none' | 'small' | 'large';
  leftToolbar?: ReactNode;
  rightToolbar?: ReactNode;
  thin?: boolean;
}

export const TopNavbar = ({
  logoVariant = 'none',
  leftToolbar,
  rightToolbar,
  thin = true,
  ...rest
}: TopNavBarProps) => {
  const theme = useTheme();
  return (
    <TopNavbarWrapper {...rest}>
      <TopNavbarToolbar thin={thin}>
        <RightToolbarContainer>
          {leftToolbar}
          {logoVariant !== 'none' && (
            <LogoWrapper>
              {logoVariant === 'small' ? (
                <ClaimscoreLogoSmall
                  style={{ marginLeft: leftToolbar ? theme.spacing(1) : 0 }}
                />
              ) : (
                <ClaimscoreLogoLarge
                  style={{ marginLeft: leftToolbar ? theme.spacing(1) : 0 }}
                />
              )}
            </LogoWrapper>
          )}
        </RightToolbarContainer>
        {rightToolbar}
      </TopNavbarToolbar>
    </TopNavbarWrapper>
  );
};
