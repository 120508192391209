import React from 'react';
import { Theme } from '@mui/material';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridValidRowModel,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { styled } from '../../utils/styled';

export type { GridRenderCellParams, GridValidRowModel, GridColDef };

const headerStyles = (theme: Theme) => ({
  backgroundColor: `${theme.palette.grey[900]}`,
  color: `${theme.palette.background.paper}`,
  '&:focus': {
    outline: 'none',
  },
  border: 'none',
});

const noHeaderStyles = { display: 'none' };

const StyledDataGrid = styled(DataGrid)<{
  noHeaderColumn: boolean;
  noPadding: boolean;
  rows: unknown;
}>(({ theme, noHeaderColumn, noPadding, rows }) => ({
  padding: noPadding ? '0' : '16px',
  border: 'none',
  '--unstable_DataGrid-radius': '0px',
  '& .MuiDataGrid-columnHeaders': noHeaderColumn
    ? noHeaderStyles
    : headerStyles(theme),
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: '12px',
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    '&:focus': {
      outline: 'none',
    },
    borderStyle: 'none',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnSeparator': { display: 'none' },
  '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '.MuiDataGrid-virtualScroller': {
    height: rows?.length === 0 ? '100px' : undefined,
  },
}));

const DataGridExtraStyles = (loading: boolean) => ({
  minHeight: loading ? '600px' : 0,
  '& .MuiDataGrid-columnHeader': headerStyles,
  '& .MuiDataGrid-sortIcon': { color: 'background.paper' },
  '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted) .MuiDataGrid-sortIcon':
    { opacity: 0.5 },
  '& .MuiDataGrid-iconButtonContainer': { width: 'unset' },
  '.MuiDataGrid-columnHeader:focus': { outline: 'none' },
  '.MuiDataGrid-cell': {
    color: 'text.secondary',
    fontSize: 16,
  },
});

interface TableProps extends DataGridProps {
  noHeaderColumn?: boolean;
  noPadding?: boolean;
}
export const Table = ({
  columns,
  rows,
  loading = false,
  noHeaderColumn = false,
  noPadding = false,
  ...rest
}: TableProps) => (
  <StyledDataGrid
    disableColumnMenu
    columns={columns}
    rows={rows}
    loading={loading}
    noHeaderColumn={noHeaderColumn}
    sx={DataGridExtraStyles(loading)}
    noPadding={noPadding}
    autoHeight={!!rows?.length}
    {...rest}
  />
);
