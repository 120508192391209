import React from 'react';
import ReactDOM from 'react-dom/client';
import { initializeLaunchDarkly } from 'ui';
import { initializeSentry } from '@shared/libs/sentry';
import { enableMockServiceWorker } from '@shared/libs/msw';
import { enableProLicenseMUI } from '@shared/libs/MUI/index.ts';
import { App } from './App.tsx';

initializeSentry();
enableMockServiceWorker();
enableProLicenseMUI();

const LDProvider = await initializeLaunchDarkly(
  import.meta.env.VITE_APP_LAUNCHDARKLY_ID
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <LDProvider>
      <App />
    </LDProvider>
  </React.StrictMode>
);
