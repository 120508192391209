import React from 'react';
import {
  CardContent as CardContentMui,
  CardContentProps as CardContentPropsMui,
} from '@mui/material';

export type CardContentProps = CardContentPropsMui;

export const CardContent = ({ ...rest }: CardContentProps) => (
  <CardContentMui {...rest} />
);
