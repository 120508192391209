import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ClaimScoreLoader, ThemeProvider, ToastProvider } from 'ui';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAppRoutes } from '@hooks/customHooks/useAppRoutes';
import { AuthProvider } from './context/AuthContext';
import { queryClient } from './shared/libs/react-query';

export const App = () => {
  const { router } = useAppRoutes();

  return (
    <ThemeProvider>
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<ClaimScoreLoader />}>
              <RouterProvider router={router} />
            </Suspense>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};
