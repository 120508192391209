import React from 'react';
import {
  Divider as DividerMui,
  DividerProps as DividerPropsMui,
} from '@mui/material';
import { styled } from '../../utils/styled';
import { ColorNeutral200, ColorNeutral800 } from '../../design-system/colors';

const StyledDivider = styled(DividerMui, {
  shouldForwardProp: (prop: string) =>
    !['light', 'fullWidth', 'noMargin'].includes(prop),
})<{ light?: boolean; fullWidth?: boolean; noMargin?: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? 'unset' : '10px 0')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'unset')};
  border-color: ${({ light }) => (light ? ColorNeutral200 : ColorNeutral800)};
`;
export interface DividerProps
  extends Pick<DividerPropsMui, 'sx' | 'orientation' | 'flexItem' | 'variant'> {
  noMargin?: boolean;
  light?: boolean;
  fullWidth?: boolean;
}

export const Divider = ({
  light = true,
  fullWidth,
  noMargin,
  ...rest
}: DividerProps) => (
  <StyledDivider
    noMargin={noMargin}
    light={light}
    fullWidth={fullWidth}
    {...rest}
  />
);
