import React from 'react';
import {
  IconButton as IconButtonMui,
  IconButtonProps as IconButtonPropsMui,
} from '@mui/material';

export type IconButtonProps = Pick<
  IconButtonPropsMui,
  | 'sx'
  | 'ref'
  | 'children'
  | 'onClick'
  | 'edge'
  | 'color'
  | 'size'
  | 'aria-labelledby'
>;

export const IconButton = ({ ...rest }: IconButtonProps) => (
  <IconButtonMui {...rest} />
);
