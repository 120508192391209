import React, { Ref, forwardRef, useMemo } from 'react';
import { SpikeContent } from '../SpikeContent';
import { SpikeInfoBlock } from '../SpikeInfoBlock/SpikeInfoBlock';
import { PageCardContainer } from '../PageCardContainer';

export interface SpikeCardProps {
  totalClaims: number;
  VSaverage: number;
  isCarousel?: boolean;
  title: string;
  borderColor: string;
  claimData: { key: string; value: number }[];
  blocks: { title: string; info: { value: number; link: string }[] }[];
}

export const SpikeCard = forwardRef(
  (
    {
      totalClaims,
      VSaverage,
      claimData,
      title,
      blocks,
      borderColor,
      isCarousel,
    }: SpikeCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const pageCardStyles = useMemo(
      () =>
        isCarousel
          ? { width: 'calc(100vw - 48px - 8px)' }
          : { flex: 1, maxWidth: '33%' },
      [isCarousel]
    );

    return (
      <PageCardContainer
        sx={pageCardStyles}
        ref={ref}
        title={title}
        borderColor={borderColor}
      >
        <SpikeContent
          totalClaims={totalClaims}
          VSaverage={VSaverage}
          claimData={claimData}
        />
        {blocks.map(({ title, info }, index: number) => (
          <SpikeInfoBlock
            key={`${index}-spike-info-blocks`}
            title={title}
            info={info}
          />
        ))}
      </PageCardContainer>
    );
  }
);
