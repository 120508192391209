import React from 'react';
import { MenuList, Stack, Typography } from '@mui/material';
import { StyledMenuItem } from '../Field/Field.styles';
import { StyledSwipeableDrawer } from '../SwipeableDrawer/SwipeableDrawer';

interface SelectDrawerProps {
  label: string;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  options: { key: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
}

export const SelectDrawer = ({
  label,
  value,
  options,
  isOpen,
  handleOpen,
  handleClose,
  onChange,
}: SelectDrawerProps) => (
  <StyledSwipeableDrawer
    open={isOpen}
    variant="temporary"
    onClose={handleClose}
    onOpen={handleOpen}
    anchor="bottom"
    isSmUp={false}
  >
    <Stack direction="column" width="100%">
      <Typography padding={3} variant="h4">
        {label}
      </Typography>
      <MenuList>
        {options.map(({ key, value: optionLabel }) => (
          <StyledMenuItem
            key={key}
            selected={key === value}
            onClick={() => onChange(key)}
          >
            <Typography variant={key === value ? 'bodyStrong' : 'body'}>
              {optionLabel}
            </Typography>
          </StyledMenuItem>
        ))}
      </MenuList>
    </Stack>
  </StyledSwipeableDrawer>
);
