import React, { ReactNode, Ref, forwardRef } from 'react';
import {
  Button as ButtonMui,
  ButtonProps as ButtonPropsMui,
} from '@mui/material';
import { styled } from '../../utils/styled';
import { Typography } from '../Typography';

export type ColorOptions = 'primary' | 'destructive' | 'neutral' | 'info';
export type VariantOptions = 'contained' | 'outlined' | 'text';

const colorMapper: Record<ColorOptions, ButtonPropsMui['color']> = {
  primary: 'primary',
  destructive: 'error',
  neutral: 'text',
  info: 'info',
};

export const IconWrapper = styled('div')(() => ({
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledButton = styled(ButtonMui)(
  ({ theme, variant, color, startIcon, ...rest }) => {
    // Calculate padding based on variant and presence of aria-label
    let padding = `${theme.spacing(1)}`;

    if (variant !== 'text') {
      if (rest['aria-label']) {
        padding = `${theme.spacing(1)} ${theme.spacing(2)}`;
      } else {
        padding = `${theme.spacing(1)}`;
      }
    }

    // Update textDecoration and backgroundColor based on conditions
    let textDecoration = 'none';
    let backgroundColor;

    if (!(variant || startIcon)) {
      textDecoration = 'underline';
    }

    if (!(variant || startIcon || rest['aria-label'])) {
      backgroundColor = 'transparent';
    }

    return {
      height: theme.spacing(4),
      '&:hover': {
        textDecoration,
        backgroundColor,
        boxShadow: 'none',
      },
      '&:disabled': {
        border: 'none',
      },
      borderRadius: theme.borderRadii.outer,
      textTransform: 'capitalize',
      color:
        variant === 'contained' && color === 'text'
          ? theme.palette.text.primaryInverted
          : undefined,
      padding,
      minWidth: '0px',
      boxShadow: 'none',
      border:
        variant === 'contained' && color !== 'error'
          ? `1px solid ${theme.palette.primary.main}`
          : undefined,
    };
  }
);

export interface ButtonProps
  extends Omit<ButtonPropsMui, 'color'>,
    Pick<ButtonPropsMui, 'disabled' | 'fullWidth' | 'children'> {
  label?: string;
  color?: ColorOptions;
  variant?: VariantOptions;
  startIcon?: ReactNode;
  'data-testid'?: string;
}

export const Button = forwardRef(
  (
    {
      label,
      color = 'primary',
      variant = 'contained',
      startIcon,
      'data-testid': dataTestId,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement>
  ) => (
    <StyledButton
      {...props}
      ref={ref}
      color={colorMapper[color]}
      variant={variant}
      startIcon={label && startIcon && <IconWrapper>{startIcon}</IconWrapper>}
      aria-label={label || props['aria-label']}
      data-testid={dataTestId}
    >
      {label && <Typography variant="subtitle">{label}</Typography>}
      {!label && startIcon && <IconWrapper>{startIcon}</IconWrapper>}
      {props.children}
    </StyledButton>
  )
);
