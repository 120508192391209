import styled from '@emotion/styled';

export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SwipeContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const SpikeContainer = styled.div`
  overflow: hidden;
  width: 300%;
  display: flex;
  gap: ${({ theme }) => theme.spacing(5)};
`;

type NavigationDotsProps = {
  active: boolean;
};

export const NavigationDots = styled.button<NavigationDotsProps>`
  width: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.spacing(1)};
  border: none;
  cursor: pointer;
  margin: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(1)};
  border-radius: ${({ theme }) => theme.borderRadii.circular};
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.primary.light};
`;
