import React, {
  createContext,
  useState,
  useMemo,
  PropsWithChildren,
  FC,
  useEffect,
} from 'react';
import { FirebaseAuth, User } from 'services/FirebaseService/auth';
import { ClaimScoreLoader, setupFeatureFlag, useLDClient } from 'ui';

interface AuthContextProps {
  user: User | null;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const launchdarklyClient = useLDClient();
  const [loadingFlags, setLoadingFlags] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);

  const login = (user: User) => {
    setUser(user);
    setIsAuthenticated(true);
  };

  const logout = () => {
    FirebaseAuth.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const unsubscribe = FirebaseAuth.subscribeToUser(async (authUser) => {
      if (!authUser) {
        logout();
      } else {
        login(authUser as User);
      }
      setLoadingUser(false);
    });
    return () => unsubscribe();
  }, [login]);

  useEffect(() => {
    if (!launchdarklyClient) return;
    if (user) {
      const setup = async () => {
        await setupFeatureFlag(launchdarklyClient, {
          id: user.uid,
          email: user.email,
        });
      };
      setup();
    }
    setLoadingFlags(false);
  }, [launchdarklyClient, user]);

  const authProviderValues = useMemo(
    () => ({
      user,
      isAuthenticated,
      isLoading: loadingFlags || loadingUser,
      login,
      logout,
    }),
    [isAuthenticated, user]
  );

  if (loadingUser || loadingFlags) {
    return <ClaimScoreLoader />;
  }

  return (
    <AuthContext.Provider value={authProviderValues}>
      {children}
    </AuthContext.Provider>
  );
};
