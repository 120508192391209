import React from 'react';
import {
  Drawer as DrawerMui,
  DrawerProps as DrawerPropsMui,
} from '@mui/material';

export type DrawerProps = DrawerPropsMui;

export const Drawer = ({ children, ...rest }: DrawerProps) => (
  <DrawerMui {...rest}>{children}</DrawerMui>
);
