import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { Button } from '../Button';
import { Typography } from '../Typography';
import { Stack } from '../Stack';

const StyledButton = styled(Button)`
  height: 150px;
  width: 260px;
  padding: ${({ theme }) => theme.spacing(2)};
  border: 1px solid ${({ theme }) => theme.palette.neutral[200]};
`;
const stackStyles = { alignItems: 'flex-start', textAlign: 'left' };
interface DescriptiveButtonProps {
  title: string;
  description: string;
  onClick: () => void;
  icon: ReactNode;
}

export const DescriptiveButton = ({
  title,
  onClick,
  description,
  icon,
}: DescriptiveButtonProps) => (
  <StyledButton variant="outlined" onClick={onClick}>
    <Stack spacing={3}>
      {icon}
      <Stack spacing={1} sx={stackStyles}>
        <Typography variant="subtitle" color="text.primary">
          {title}
        </Typography>
        <Typography variant="body" color="text.secondary">
          {description}
        </Typography>
      </Stack>
    </Stack>
  </StyledButton>
);
