import { useEffect } from 'react';
import {
  init,
  replayIntegration,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

export const initializeSentry = () => {
  if (
    import.meta.env.VITE_SENTRY_DSN &&
    import.meta.env.VITE_CLAIMSCORE_ENV === 'prod'
  ) {
    init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost'],
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
