import React from 'react';
import { styled } from '../../utils/styled';
import { getSize } from '../../utils/get-size';
import { Typography } from '../Typography';
import { ColorYellow600, ColorRed600 } from '../../design-system/colors';

export type BadgeSize = 'small' | 'large';
export type BadgeColor = 'warning' | 'error';

type InternalBadgeSize = BadgeSize | 'tiny';

const sizes: Record<InternalBadgeSize, number> = {
  tiny: 8,
  small: 16,
  large: 24,
};

interface BadgeProps {
  size: BadgeSize;
  count?: number;
  color: BadgeColor;
  'data-testid'?: string;
}

const colorMapper = {
  error: ColorRed600,
  warning: ColorYellow600,
};

const StyledBadge = styled('div')<{
  sizing: InternalBadgeSize;
  color: BadgeColor;
}>`
  ${({ sizing }) => getSize(sizes[sizing])};
  background-color: ${({ color }) => colorMapper[color]};
  color: ${({ color }) => colorMapper[color]};
  border-radius: ${({ theme }) => theme.borderRadii.circular};
  color: ${({ theme }) => theme.palette.paper[200]};
  justify-content: center;
  display: flex;
  align-items: center;
  animation: bounce 1s ease 2;
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20%);
    }
    60% {
      transform: translateY(-10%);
    }
  }
`;

const getSizeLabel = (size: BadgeSize, count?: number): InternalBadgeSize => {
  if (!count && size === 'small') {
    return 'tiny';
  }
  if (!count && size === 'large') {
    return 'small';
  }
  return size;
};

const getCount = (count?: number) => {
  if (count === 0) {
    return '';
  }
  if (count && count > 9) {
    return '9+';
  }
  return count;
};

export const Badge = ({
  count,
  size,
  color,
  'data-testid': dataTestId,
}: BadgeProps) => (
  <StyledBadge
    sizing={getSizeLabel(size, count)}
    color={color}
    data-testid={dataTestId}
  >
    <Typography variant={size === 'small' ? 'captionStrong' : 'body'}>
      {getCount(count)}
    </Typography>
  </StyledBadge>
);
