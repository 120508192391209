/* eslint-disable @typescript-eslint/no-empty-object-type */
import React from 'react';
import { createTheme, Theme as MuiTheme } from '@mui/material';
import { color } from './colors.json';
import { typography } from './typography.json';
import '@fontsource-variable/dm-sans';

declare module '@mui/material/styles' {
  type BorderRadii = {
    inner: string;
    outer: string;
    mobileDrawer: string;
    circular: string;
  };

  interface ColorPalette {
    neutral: Record<string, string>;
    paper: Record<string, string>;
    green: Record<string, string>;
    blue: Record<string, string>;
    red: Record<string, string>;
    yellow: Record<string, string>;
    orange: Record<string, string>;
    violet: Record<string, string>;
    background: {
      default: string;
      paper: string;
      dark: string;
    };
    text: {
      primary: {
        main: string;
        inverted: string;
      };
      secondary: {
        main: string;
        inverted: string;
      };
    };
  }

  interface TypeBackground {
    default: string;
    paper: string;
    dark: string;
  }

  interface TypeText {
    primary: string;
    main: string;
    primaryInverted: string;
    secondary: string;
    secondaryInverted: string;
  }

  interface Palette extends ColorPalette {}
  interface PaletteOptions extends ColorPalette {}

  interface Constants {
    navigationDrawerMinimizedWidth: string;
  }

  interface Theme {
    borderRadii: BorderRadii;
    boxShadow: string;
    constants: Constants;
    stroke: (hex: string) => string;
  }
  interface ThemeOptions {
    borderRadii: BorderRadii;
    boxShadow: string;
    constants: Constants;
    stroke: (hex: string) => string;
  }

  interface TypographyVariants {
    captionStrong: React.CSSProperties;
    body: React.CSSProperties;
    bodyStrong: React.CSSProperties;
    subtitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    captionStrong: React.CSSProperties;
    body: React.CSSProperties;
    bodyStrong: React.CSSProperties;
    subtitle: React.CSSProperties;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    text: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    captionStrong: true;
    body: true;
    bodyStrong: true;
    subtitle: true;
    body1: false;
    body2: false;
    h6: false;
    button: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;
  }
}

declare module '@emotion/react' {
  export interface Theme extends MuiTheme {
    typography: {
      caption: React.CSSProperties;
      captionStrong: React.CSSProperties;
      body: React.CSSProperties;
      bodyStrong: React.CSSProperties;
      subtitle: React.CSSProperties;
      h5: React.CSSProperties;
      h4: React.CSSProperties;
      h3: React.CSSProperties;
      h2: React.CSSProperties;
      h1: React.CSSProperties;
    };
  }
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  palette: {
    ...color,
    primary: {
      main: color.green[600],
      light: color.green[200],
      dark: color.green[800],
    },
    info: {
      main: color.blue[600],
      light: color.blue[200],
      dark: color.blue[800],
    },
    warning: {
      main: color.yellow[600],
      light: color.yellow[200],
      dark: color.yellow[800],
    },
    error: {
      main: color.red[600],
      light: color.red[200],
      dark: color.red[800],
    },
    success: {
      main: color.green[600],
      light: color.green[200],
      dark: color.green[800],
    },
    background: {
      default: color.paper[400],
      paper: color.paper[200],
      dark: color.neutral[1000],
    },
    text: {
      main: color.neutral[600],
      primary: color.neutral[900],
      primaryInverted: color.neutral[100],
      secondary: color.neutral[600],
      secondaryInverted: color.neutral[400],
    },
  },
  constants: {
    navigationDrawerMinimizedWidth: '72px',
  },
  borderRadii: {
    inner: '4px',
    outer: '8px',
    mobileDrawer: '24px',
    circular: '50%',
  },
  spacing: [4, 8, 16, 24, 36, 48, 64],
  typography: {
    fontFamily: 'DM Sans Variable',
    ...typography,
  },
  boxShadow: '0px 1px 2px rgba(100, 116, 139, 0.16)',
  stroke: (hex: string) => `1px solid ${hex}`,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          fontFamily: 'DM Sans Variable',
          boxSizing: 'border-box',
          margin: 0,
          padding: 0,
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        body: {
          backgroundColor: color.paper[400],
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
          ...typography.body,
        },
        a: {
          textDecoration: 'none',
        },
        '.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation': {
          boxShadow: '0px 1px 2px rgba(100, 116, 139, 0.16)',
        },
        '.MuiList-root.MuiMenu-list': {
          padding: 0,
        },
      },
    },
  },
});
