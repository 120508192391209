import claimScoreApiServiceClient from '../client';
import {
  OrganizationRoleResponse,
  UserPermissionsAllOrganizationsResponse,
  UserPermissionsByOrganizationResponse,
  OrganizationRolePayload,
  UpdateCaseRolePayload,
  UpdateOrganizationRolePayload,
} from './types';

export const getUserPermissionsAllOrganizations =
  async (): Promise<UserPermissionsAllOrganizationsResponse> => {
    const { data } = await claimScoreApiServiceClient.get(`/permissions`);

    return data;
  };

export const getUserPermissionsByOrganization = async (
  organizationId: string
): Promise<UserPermissionsByOrganizationResponse> => {
  const { data } = await claimScoreApiServiceClient.get(
    `/permissions/organizations/${organizationId}`
  );

  return data;
};

export const updateOrganizationRole = async ({
  accountId,
  organizationId,
  roleName,
}: UpdateOrganizationRolePayload): Promise<
  Pick<OrganizationRoleResponse, 'accountID' | 'organizationID' | 'roleName'>
> => {
  const { data } = await claimScoreApiServiceClient.put(
    `/permissions/${accountId}/organizations/${organizationId}`,
    {
      roleName,
    }
  );

  return data;
};

export const deleteOrganizationRole = async ({
  accountId,
  organizationId,
}: Pick<OrganizationRolePayload, 'accountId' | 'organizationId'>): Promise<
  Pick<OrganizationRoleResponse, 'accountID' | 'organizationID'>
> => {
  const { data } = await claimScoreApiServiceClient.delete(
    `/permissions/${accountId}/organizations/${organizationId}`
  );

  return data;
};

export const updateRoleByCase = async ({
  accountId,
  caseId,
  roleName,
}: UpdateCaseRolePayload): Promise<
  Pick<OrganizationRoleResponse, 'accountID' | 'caseID' | 'roleName'>
> => {
  const { data } = await claimScoreApiServiceClient.put(
    `/permissions/${accountId}/cases/${caseId}`,
    {
      roleName,
    }
  );

  return data;
};

export const deleteRoleByCase = async ({
  accountId,
  caseId,
}: Pick<OrganizationRolePayload, 'accountId' | 'caseId'>): Promise<
  Pick<OrganizationRoleResponse, 'accountID' | 'caseID'>
> => {
  const { data } = await claimScoreApiServiceClient.delete(
    `/permissions/${accountId}/cases/${caseId}`
  );

  return data;
};
