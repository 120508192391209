import React from 'react';
import { Stack, Typography, StackProps } from '@mui/material';
import { Divider } from '../Divider';

const addFlexStyle = {
  display: 'flex',
};

const labelStyle = {
  ...addFlexStyle,
  margin: 0,
  width: '96px',
};

interface DrawerItemProps {
  label: string;
  item: React.ReactNode;
  sx?: StackProps['sx'];
}

export const DrawerItem: React.FC<DrawerItemProps> = ({ label, item, sx }) => (
  <>
    <Stack direction="row" alignItems="center" gap={3} sx={sx}>
      <Typography sx={labelStyle} color="text.secondary" variant="caption">
        {label}
      </Typography>
      <Typography sx={addFlexStyle} color="text.secondary" variant="body">
        {item}
      </Typography>
    </Stack>
    <Divider noMargin />
  </>
);
