export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
});

export const currencyFormatterInteger = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

export const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

export const numberFormatterNoDecimals = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

export const numberFormatterWithUnits = (number: number) => {
  if (number > 999999)
    return `${numberFormatterNoDecimals.format(number / 1_000_000)}M`;
  if (number > 99999) {
    return `${numberFormatterNoDecimals.format(number / 1_000)}K`;
  }
  return numberFormatterNoDecimals.format(number);
};
