import React, { useCallback } from 'react';
import { Button, RefreshIcon, Typography, styled } from 'ui';
import { ERROR_PAGE_CONTAINER } from './ErrorPage.testId';
import { useNavigate } from 'react-router-dom';

const ContentWrapper = styled('div')`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
  margin-bottom: 3;
`;

const styledTypographyStyles = {
  p: 2,
};

type ErrorPageProps = {
  title: string;
  subtitle?: string;
  reload?: boolean;
};

export const ErrorPage = ({ title, subtitle, reload }: ErrorPageProps) => {
  const navigate = useNavigate();

  const handleReloadClick = useCallback(() => navigate(0), [window.location]);

  return (
    <ContentWrapper data-testid={ERROR_PAGE_CONTAINER}>
      <StyledTypography variant="h3">{title}</StyledTypography>
      <StyledTypography sx={styledTypographyStyles} variant="subtitle">
        {subtitle}
      </StyledTypography>
      {reload && (
        <Button
          onClick={handleReloadClick}
          variant="text"
          label="Refresh"
          endIcon={<RefreshIcon />}
        />
      )}
    </ContentWrapper>
  );
};
