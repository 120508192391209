import React, { ReactNode, forwardRef, Ref } from 'react';
import { CardActions } from '@mui/material';
import {
  StyledBorder,
  StyledCardContent,
  StyledCardHeader,
  StyledCard,
  StyledTypography,
} from './PageCardContainer.styles';
import { Divider } from '../Divider';
import { Typography } from '../Typography';
import { Stack } from '../Stack';
import { CardProps } from '../Card';

export interface PageCardContainerProps extends CardProps {
  title: string;
  subheader?: string;
  borderColor?: string;
  Toolbar?: React.JSX.Element;
  cardAction?: ReactNode;
  children?: React.ReactNode;
  noPadding?: boolean;
  width?: string;
  divider?: boolean;
}

const PageCardContainerComponent = forwardRef(
  (
    {
      title,
      subheader,
      cardAction,
      Toolbar,
      children,
      borderColor,
      noPadding,
      divider = true,
      ...rest
    }: PageCardContainerProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <StyledCard {...rest} ref={ref}>
      <StyledCardHeader
        action={Toolbar}
        title={
          <Stack direction="row">
            {borderColor && <StyledBorder borderColor={borderColor} />}
            <Stack direction="column" spacing={0}>
              <Typography variant="h5">{title}</Typography>
              {subheader && (
                <StyledTypography variant="subtitle">
                  {subheader}
                </StyledTypography>
              )}
            </Stack>
          </Stack>
        }
      />
      {divider && <Divider orientation="horizontal" light fullWidth noMargin />}
      <StyledCardContent noPadding={noPadding}>{children}</StyledCardContent>
      {cardAction && (
        <StyledCard>
          {divider && (
            <Divider orientation="horizontal" light fullWidth noMargin />
          )}
          <CardActions disableSpacing>{cardAction}</CardActions>
        </StyledCard>
      )}
    </StyledCard>
  )
);
export const PageCardContainer = PageCardContainerComponent;
