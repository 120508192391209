import { styled } from '../../utils/styled';
import { Card } from '../Card';
import { Box } from '../Box';

export const CaseCardWrapper = styled(Box)`
  padding: 16px;
`;

export const CaseCardIconWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

export const StyledCard = styled(Card)`
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(57, 57, 57, 0.16);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
