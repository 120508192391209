import React from 'react';
import { ErrorPage } from '../ErrorPage/ErrorPage';

export const ErrorBoundaryPage = () => (
  <ErrorPage
    title="Oops, something went wrong."
    subtitle="There was an unexpected error, please refresh the page to return to ClaimScore."
    reload
  />
);
