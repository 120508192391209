import React from 'react';
import { Stack as StackMui, StackProps as StackPropsMui } from '@mui/material';

export type StackProps = Pick<
  StackPropsMui,
  | 'direction'
  | 'spacing'
  | 'children'
  | 'sx'
  | 'gap'
  | 'divider'
  | 'mb'
  | 'mt'
  | 'flex'
  | 'alignItems'
  | 'width'
  | 'justifyContent'
  | 'padding'
  | 'flexGrow'
  | 'flexWrap'
>;

export const Stack = ({ ...rest }: StackProps) => <StackMui {...rest} />;
