import React from 'react';
import { SideNavbar as SideNavbarUI } from 'ui';
import { MainSideNavbarContent } from './MainSideNavbarContent';

export interface SideNavbarProps {
  open?: boolean;
  onClose?: () => void;
  onMouseOver?: () => void;
}

export const SideNavbar = ({ open, onClose, onMouseOver }: SideNavbarProps) => (
  <SideNavbarUI open={open} onClose={onClose} onMouseOver={onMouseOver}>
    <MainSideNavbarContent open={open} onClose={onClose} />
  </SideNavbarUI>
);
