import React from 'react';
import {
  Checkbox as CheckboxUi,
  FormControlLabel,
  CheckboxProps as CheckboxPropsMui,
} from '@mui/material';
import { styled } from '../../utils/styled';
import { ColorNeutral300, ColorNeutral600 } from '../../design-system/colors';
import { Typography } from '../Typography';

const StyledCheckboxUi = styled(CheckboxUi)`
  &.Mui-checked:hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;
export interface CheckboxProps
  extends Pick<
    CheckboxPropsMui,
    'checked' | 'name' | 'onChange' | 'icon' | 'checkedIcon'
  > {
  label: string;
  value?: string;
  disabled?: boolean;
}

export const Checkbox = ({
  disabled,
  value,
  label,
  ...props
}: CheckboxProps) => (
  <FormControlLabel
    disabled={disabled}
    value={value}
    label={
      <Typography
        color={disabled ? ColorNeutral300 : ColorNeutral600}
        variant="body"
      >
        {label}
      </Typography>
    }
    control={
      <StyledCheckboxUi {...props} inputProps={{ 'aria-label': label }} />
    }
  />
);
